<template>
  <el-aside class="aside" width="256px">
    <div class="logo flex-center-column">
      <img v-if="logoInfo[0] && logoInfo[0].logoPath" :src="`${this.Services.Authorization}${logoInfo[0].logoPath}`" alt="" />
      <img v-else src="../../assets/img/logo_w.png" alt="" />
    </div>
    <el-scrollbar class="left-nav-wrap" style="height: calc(100vh - 80px)">
      <!-- 具体要包裹的内容 -->
      <el-menu :default-active="$route.path" class="el-menu-vertical-demo" text-color="#fff" active-text-color="#448aeb" router accordion unique-opened>
        <!-- <NavItem v-for="menu in AllMenus" :key="menu.url" :item="menu" :basePath="menu.url" /> -->
        <NavItem class="item" v-for="menu in AllMenus" :item="menu" :basePath="menu.url" />
      </el-menu>
      <div class="aboutUs flex-end" v-if="ClientId != FairClient" :class="IsChangeLoginPage?'bg-img':''">
        <div class="right-info">
          <div>您的专属客服</div>
          <template v-if="ClientId == YunClient || ClientId == TotalClient || ClientId == AssociationClient">
            <div class="phone">
              <i class="el-icon-headset"></i>
              <span>{{ orgCustomerInfo.customerName ? orgCustomerInfo.customerName : '暂无' }}</span>
            </div>
            <div class="phone">
              <i class="el-icon-phone-outline"></i>
              <span>{{ orgCustomerInfo.customerPhone ? orgCustomerInfo.customerPhone : '暂无' }}</span>
            </div>
          </template>
          <template v-else>
            <div class="phone">
              <i class="el-icon-mobile"></i>
              <span>{{ agentInfo.contactPhone ? agentInfo.contactPhone : '暂无' }}</span>
            </div>
            <div class="phone">
              <i class="el-icon-phone-outline"></i>
              <span>{{ agentInfo.phone ? agentInfo.phone : '暂无' }}</span>
            </div>
          </template>
        </div>
      </div>
    </el-scrollbar>
  </el-aside>
</template>

<script>
import NavItem from './NavItem.vue' //子组件
import { User } from '@/components/HospitalDomain/User.js'
export default {
  name: 'Nav',
  components: { NavItem },
  data() {
    var user = new User(this.TokenClient, this.Services.Authorization)
    return {
      UserDomain: user,
      AllMenus: JSON.parse(sessionStorage.getItem('AllMenus')),
      agentInfo: {},
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      logoInfo: [],
      orgCustomerInfo: {},
    }
  },
  mounted() {
    this.getData()
    this.OrgLogoAll()
    this.getOrgCustomer()
  },
  methods: {
    getData() {
      var _this = this
      _this.UserDomain.AgentDetails(
        this.userInfo.agentId,
        function (data) {
          _this.agentInfo = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    OrgLogoAll() {
      var _this = this
      _this.UserDomain.OrgLogoAll(
        this.userInfo.organizationId,
        'fff',
        function (data) {
          _this.logoInfo = data.data.results
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getOrgCustomer() {
      var _this = this
      _this.UserDomain.OrgCustomer(
        this.userInfo.organizationId,
        function (data) {
          _this.orgCustomerInfo = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep .el-scrollbar__bar.is-vertical {
  opacity: 0;
}

.logo {
  height: 80px;
  background: var(--base--Color);
}

.logo img {
  height: auto;
  width: 200px;
  vertical-align: middle;
}

.logo2 {
  padding-bottom: 15px;
  background: var(--base--Color);
}

.logo2 img {
  width: 150px;
  margin-top: 15px;
  height: 53px;
  vertical-align: middle;
}

.logo span {
  line-height: 50px;
  font-size: 28px;
  color: #ffffff;
  font-weight: bold;
  vertical-align: middle;
  display: inline-block;
  margin: 15px 0 0 15px;
}

::v-deep .left-nav-wrap {
  background: var(--base--bg);
}

.left-nav-wrap .iconfont {
  font-size: 18px;
  margin-right: 10px;
  color: #ffffff !important;
  position: relative;
}

.aboutUs {
  width: 240px;
  height: 106px;
  background: url('../../assets/img/bg_service.png') no-repeat;
  color: #ffffff;
  margin: 0 15px;
  margin: 20px auto 40px;
}
.bg-img{
  background: url('../../assets/img/bg_service2.png') no-repeat !important;
}

.aboutUs .right-info {
  width: 65%;
}

.aboutUs .right-info .phone {
  color: #f0ff00;
  font-size: 12px;

  i {
    margin-right: 2px;
  }
}

.el-menu-item .iconfont {
  font-size: 16px;
  color: #ffffff;
}

.nav-subtit {
  padding-left: 20px;
  color: #ffffff;
  line-height: 3;
}

::v-deep .el-menu-item-group__title {
  font-family: PingFang SC;
  color: #fff !important;
  padding-left: 5px !important;
  line-height: 30px;
  opacity: 0.8;
  padding: 0;
}

::v-deep ul.el-menu-vertical-demo .item:first-child .el-menu-item-group__title {
  padding-top: 12px;
}
</style>
<style>
.aside {
  height: 100%;
}

.el-menu {
  height: 100%;
  background: none;
  border: none;
  padding-left: 10px;
}

.el-menu-item,
.el-submenu__title {
  height: 42px !important;
  line-height: 42px !important;
  border-radius: 25px 0 0 25px;
  background: none !important;
  padding-left: 20px !important;
}

.el-submenu__title:hover,
.el-menu-item:hover {
  width: 100%;
  background: var(--base--Color) !important;
  border-radius: 25px 0 0 25px;
  transition: none;
}

.el-menu-item.is-active {
  background: #f5f6fa !important;
  border-radius: 25px 0 0 25px;
  position: relative;
  padding-right: 0px !important;
  color: var(--base--Color) !important;
}

.el-menu-item.is-active .box {
  width: 40px;
  position: absolute;
  right: 0;
  top: -18px;
  z-index: 99;
  height: 78px !important;
  line-height: 78px !important;
  background: url('../../assets/img/bg11.png') no-repeat right;
}

.el-menu-item .titleBox {
  width: 100%;
  position: relative;
  z-index: 999;
}

.el-submenu__title i {
  color: #ffffff;
  margin-right: 10px;
}

.el-menu-item i {
  color: #ffffff;
  margin-right: 10px;
}

.el-scrollbar__wrap {
  overflow-x: auto;
}

.aside .el-scrollbar__thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
</style>
